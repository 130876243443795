<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="FinisherApi.isError" :api="FinisherApi" />
          <!-- EOC -->
          <div class="pe-2 ps-3 app-title text-h5">
            {{ $t("string.finisherGiftTitle") }}
            <v-spacer></v-spacer>
            <CloseBtn size="40" :callback="exit" />
          </div>
          <div class="pa-5" v-if="finisher">
            <v-img
              v-if="finisher.status == 'pending'"
              :src="require('@/assets/explore/gift_2.png')"
              width="300"
              class="mx-auto"
              style="margin-top: -70px; margin-bottom: -30px"
            ></v-img>
            <v-img
              v-else
              :src="require('@/assets/explore/gift_3.png')"
              width="300"
              class="mx-auto"
              style="margin-top: -50px; margin-bottom: -30px"
            ></v-img>
            <div class="text-center mb-4 mx-auto">
              <div class="text-h4">
                {{ $t("string.finisherGiftMsg1") }}
              </div>
              <div class="text-h5 mt-5" v-if="finisher.status == 'pending'">
                {{ $t("string.finisherGiftMsg2") }}
              </div>
              <div class="text-h5" v-else>
                {{ $t("string.finisherGiftMsg3") }}
              </div>
            </div>
            <v-card
              max-width="300"
              flat
              class="ma-auto py-2 px-5 d-flex flex-column align-left text-h6 letter"
              rounded="0"
            >
              <div>{{ finisher.name }}</div>
              <div>{{ finisher.classroomName }}</div>
              <div>{{ finisher.institutionName }}</div>
              <div>{{ finisher.town }}</div>
            </v-card>
            <!-- <div
              class="text-center mb-4 mx-auto"
              style="max-width: 250px"
              v-if="finisher.status != 'pending'"
            >
              <div>
                Contact 012-xxxxxxxx if your school didn't receive your gift.
              </div>
            </div> -->
            <div>
              <div
                class="text-right"
                v-if="
                  auth.Group.type != 'institution' &&
                  finisher.status == 'pending' &&
                  !$moment().isAfter(series.timestampEnd)
                "
              >
                <v-btn
                  height="30"
                  x-small
                  text
                  color="blue"
                  large
                  @click="
                    () => {
                      $router.push({
                        name: 'PageEventGiftForm',
                        query: { action: 'update' },
                      });
                    }
                  "
                >
                  {{ $t("string.change") }}
                </v-btn>
              </div>
              <div class="text-center mt-6">
                <v-btn
                  class="text-h6"
                  height="34"
                  color="yellow"
                  large
                  width="300"
                  @click="exit()"
                >
                  {{ $t("action.ok") }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    series: (state) => state.series.data,
    settings: (state) => state.settings.data,
  }),
  props: [
    //
  ],
  data: () => ({
    FinisherApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    finisher: null,
  }),
  created() {
    this.FinisherApi.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/event/finisher/load";

    this.FinisherApi.callbackReset = () => {
      this.FinisherApi.isLoading = true;
      this.FinisherApi.isError = false;
    };

    this.FinisherApi.callbackError = (e) => {
      this.FinisherApi.isLoading = false;
      this.FinisherApi.isError = true;
      this.FinisherApi.error = e;
    };
    this.FinisherApi.callbackSuccess = (resp) => {
      this.FinisherApi.isLoading = false;
      this.finisher = resp.Finisher;
      if (this.finisher == null) {
        this.saveFinisher();
      }
      let Sfx = new Audio(require("@/assets/sounds/win.wav"));
      Sfx.play();
      Sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
    };
    this.FinisherApi.params = {
      eventCode: this.$route.params.code,
    };
  },
  mounted() {
    if (this.series.isCompleted) {
      this.$api.fetch(this.FinisherApi);
    } else {
      this.exit();
    }
  },
  methods: {
    saveFinisher() {
      this.FinisherApi.url =
        this.$api.servers.event +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/event/finisher/save";

      this.FinisherApi.callbackReset = () => {
        this.FinisherApi.isLoading = true;
        this.FinisherApi.isError = false;
      };

      this.FinisherApi.callbackError = (e) => {
        this.FinisherApi.isLoading = false;
        this.FinisherApi.isError = true;
        this.FinisherApi.error = e;
      };
      this.FinisherApi.callbackSuccess = (resp) => {
        this.FinisherApi.isLoading = false;
        this.finisher = resp;
      };
      if (this.auth.Classroom) {
        this.schoolYear = this.auth.Classroom.schoolYear;
      } else {
        this.schoolYear = this.auth.User.schoolYear;
      }
      this.FinisherApi.params = {
        name: this.auth.User.name,
        classroomName: this.auth.Classroom.name,
        institutionName: this.auth.Institution.name,
        town: this.auth.Institution.town,
        eventCode: this.$route.params.code,
        institutionId: this.auth.Institution ? this.auth.Institution.id : null,
        classroomId: this.auth.Classroom ? this.auth.Classroom.id : null,
        schoolYear: this.schoolYear,
      };
      this.$api.fetch(this.FinisherApi);
    },
    exit() {
      this.$router.push({
        name:
          this.series.type == "book" ? "PageEventABCBooks" : "PageEventLevels",
      });
    },
  },
};
</script>

    <style scoped>
.app-title {
  z-index: 99;
  position: fixed;
  top: 0;
  max-width: 480px !important;
  width: 100%;
  display: flex;
  align-items: center;
  height: 60px;
  box-shadow: 0px 5px 2px rgba(128, 128, 128, 0.5) !important;
  border: #caad87 5px solid;
  margin: auto;
  color: #fff;
  background-color: #f7efe3;
  text-shadow: #cbb18f 3px 0px 0px, #cbb18f 2.83487px 0.981584px 0px,
    #cbb18f 2.35766px 1.85511px 0px, #cbb18f 1.62091px 2.52441px 0px,
    #cbb18f 0.705713px 2.91581px 0px, #cbb18f -0.287171px 2.98622px 0px,
    #cbb18f -1.24844px 2.72789px 0px, #cbb18f -2.07227px 2.16926px 0px,
    #cbb18f -2.66798px 1.37182px 0px, #cbb18f -2.96998px 0.42336px 0px,
    #cbb18f -2.94502px -0.571704px 0px, #cbb18f -2.59586px -1.50383px 0px,
    #cbb18f -1.96093px -2.27041px 0px, #cbb18f -1.11013px -2.78704px 0px,
    #cbb18f -0.137119px -2.99686px 0px, #cbb18f 0.850987px -2.87677px 0px,
    #cbb18f 1.74541px -2.43999px 0px, #cbb18f 2.44769px -1.73459px 0px,
    #cbb18f 2.88051px -0.838247px 0px;
}
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 80px 0 40px 0;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}

.letter {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  margin: 26px auto 0;
  padding: 24px;
  position: relative;
  width: 80%;
}
.letter:before,
.letter:after {
  content: "";
  height: 98%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.letter:before {
  background: #fafafa;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}
.letter:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}
</style>